<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      {{'default_' + type + '_operational_price_lists' | translate}}
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt" routerLink="/operational-price-list/{{type}}/form" [attr.uk-tooltip]="'create_default_'+type+'_operational_price_list' | translate"><span uk-icon="plus"></span></button>
    </p>
  </div>
  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="uk-grid uk-margin-bottom">
        <div class="uk-width-1-3@m">
          <button class="uk-button uk-button-primary uk-width-1-1"
                  [class.uk-button-primary]="type==='income'" (click)="changeType('income')">
            <span>Cennik przychodowy</span>
          </button>
        </div>
        <div class="uk-width-1-3@m">
          <button class="uk-button uk-button-primary uk-width-1-1"
                  [class.uk-button-primary]="type==='cost'" (click)="changeType('cost')">
            <span>Cennik kosztowy</span>
          </button>
        </div>
      </div>
      <div class="mobile-overflow">
        <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider"  style="width: 100% !important;" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead>
          <tr>
            <th>{{'name' | translate}} {{type}}</th>
            <th>{{'begin_date' | translate}}</th>
            <th>{{'special_association' | translate}}</th>
            <th>{{(type === 'cost' ? 'courier' : 'country') | translate}}</th>
          </tr>
          </thead>
          <tbody *ngIf="priceLists?.length > 0">
          <tr *ngFor="let priceList of priceLists"
              [routerLink]="'/operational-price-list/'+type+'/form/'+priceList.id"
              [class.uk-active]="currentActivePriceLists.includes(priceList.id)"
              [title]="currentActivePriceLists.includes(priceList.id)? 'Bieżący aktywny cennik' : ''"
          >
            <td>
              {{priceList.name}}
            </td>
            <td>
              {{priceList.begin_date}}
            </td>
            <td>
              {{priceList.special_association | translate }}
            </td>
            <td>{{ type === 'cost' ? priceList.courier : priceList.country }}</td>
          </tr>
          </tbody>
          <tbody *ngIf="priceLists?.length == 0">
          <tr>
            <td colspan="2">{{'no_data' | translate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

