import {Component, Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ToastsService} from '../../../services/toasts.service';
import {environment} from '../../../../environments/environment';
import {KeyCode} from "../../../enums/key-code";
import {UserModel} from "../../../models/user.model";
import {AuthService} from "../../../services/auth/auth.service";
import {TagsService} from "../../../services/tags/tags.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-set-workstation',
  templateUrl: './set-workstation.component.html',
  styleUrls: ['./set-workstation.component.scss']
})
export class SetWorkstationComponent implements OnInit, OnDestroy {
  @ViewChild('barcodeInput') barcodeInput: ElementRef;
  @ViewChild('chosenClientNameInput') chosenClientNameInput: ElementRef;
  @ViewChild('postalCodeInput') postalCodeInput: ElementRef;
  @ViewChild('weightInput') weightInput: ElementRef;

  activeSection: string = 'barcodeForm';
  apiurl: string = null;

  parcelModel = null;

  statusTypes: any[] = [];
  clientName: string = '(kliknij aby wybrać)';
  problematicSectors = [];
  statusTypeId = 55;

  form = {
    code: null,
  };

  barcodeInputFocusLocked = false;

  constructor(
    private toastsService: ToastsService,
    private authService: AuthService,
    private tagsService: TagsService,
    private router: Router,
  ) {
    this.apiurl = environment.api;
  }

  ngOnInit() {
    this.keyReading();
    this.setFocusOnBarcodeInputBackgroundTask();
    setTimeout(
      () => {
        this.setFocusOnBarcodeInput();
      }, 0
    );
  }

  scan() {
    this.tagsService.scan(this.form.code).subscribe(
      (response: any) => {
        this.toastsService.showMessage('Pomyślnie ustawiono stanowisko!', 's');

        this.authService.whoami().subscribe(
          (userModel: UserModel) => {
            console.log(userModel.workstation.type.code);

            switch (userModel.workstation.type.code) {
              case 'PRZJ':
              case "ZWRT":
                this.router.navigateByUrl('/parcel-from-number');
                break;

              case 'PRBL':
                this.router.navigateByUrl('/resolve-problematic');
                break;

              case 'SRTW':
                this.router.navigateByUrl('/parcel-classification');
                break;
            }
          }
        )
      },
      (error: any) => {
        switch (error.status) {
          case 404:
            this.toastsService.showMessage('Stanowisko nie istnieje!', 'e');
            break;
          case 406:
            this.toastsService.showMessage(error.error.message, 'e');
            break;
          case 423:
            this.toastsService.showMessage('Stanowisko jest już zajęte!', 'e');
            break;
        }
      }
    );
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.listenerAction, false);
  }

  keyReading() {
    document.addEventListener('keyup', this.listenerAction, false);
  }


  setFocusOnBarcodeInputBackgroundTask() {
    setTimeout(
      () => {
        if (!this.barcodeInputFocusLocked) {
          this.setFocusOnBarcodeInput();
        }
        this.setFocusOnBarcodeInputBackgroundTask();
      },
      2000
    );
  }

  setFocusOnBarcodeInput() {
    this.barcodeInput.nativeElement.focus();
    this.activeSection = 'barcodeForm';
  }

  listenerAction = (e: KeyboardEvent) => {
    if (KeyCode.ESCAPE === e.keyCode) {
      if (this.barcodeInputFocusLocked) {
        this.setFocusOnBarcodeInput();
      }

      this.barcodeInputFocusLocked = !this.barcodeInputFocusLocked;
    }
    if (KeyCode.TAB ===e.keyCode) {
      this.barcodeInputFocusLocked = true;
    }

    if (KeyCode.DELETE === e.keyCode) {
      switch (this.activeSection) {
        case 'barcodeForm':
          this.form.code = '';
          break;
      }
    }
    return;
  }
}
