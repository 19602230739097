import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CredentialsService} from '../../../services/credentails/credentials.service';
import {ClientsService} from '../../../services/clients/clients.service';
import {ActivatedRoute} from "@angular/router";
import {Subject} from "rxjs";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ToastsService} from "../../../services/toasts.service";
import {DataTableDirective} from "angular-datatables";
import {OperationalPriceListService} from "../../../services/operational-price-list/operational-price-list.service";
@Component({
  selector: 'app-operational-price-list',
  templateUrl: './operational-price-list.component.html',
  styleUrls: ['./operational-price-list.component.scss']
})
export class OperationalPriceListComponent implements OnInit {
  currentActivePriceLists = [];
  dtTranslation = null;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  public dtInit = false;
  type: string;
  priceLists = [];
  apiurl = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private toastsService: ToastsService,
    private operationalPriceListService: OperationalPriceListService

  ) {
    this.dtTranslation = toastsService.dtTranslation;
  }
  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.paramMap.get('type');
    this.apiurl = environment.api + 'app/v1/operational_price_list/' + this.type + '/list';
    this.getPriceLists();
    this.operationalPriceListService.currentActive('cost').subscribe(
      (response: []) => {
        this.currentActivePriceLists = response;
        this.operationalPriceListService.currentActive('income').subscribe(
          (response: []) => {
            for (const item of response) {
              this.currentActivePriceLists.push(item);
            }
          }
        );
      }
    );
  }

  getPriceLists() {
    const that = this;
    if (!this.dtInit) {
      setTimeout(
        () => {
          this.dtTrigger.next();
        }, 100
      );
    } else {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtInit = false;
        setTimeout(
          () => {
            this.dtTrigger.next();
          }, 100
        );
      });
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      searching: true,
      ordering: true,
      processing: true,
      language: this.dtTranslation,
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post(
            this.apiurl,
            dataTablesParameters
          ).subscribe((resp: any) => {
          that.priceLists = resp.data;
          this.dtInit = true;

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        { name: 'name', data: 'name' },
        { name: 'begin_date', data: 'begin_date' },
        { name: 'special_association', data: 'special_association' },
        { title: this.type === 'cost' ? 'Kurier' : 'Kraj', data: this.type === 'cost' ? 'courier' : 'country' }
      ]
    }
  }

  changeType(type) {
    this.priceLists = [];
    this.type = type;
    this.apiurl = environment.api + 'app/v1/operational_price_list/' + this.type + '/list';
    this.getPriceLists();
  }
}
